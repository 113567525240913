import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/app/globals.css");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components-ai/providers.tsx");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components-ai/ui/sonner.tsx");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/aistaffButton.tsx");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/footer/footer.tsx");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/navbar/navigation.tsx");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/ScrollingBar.tsx");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/TranslationsProvider.tsx");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}")